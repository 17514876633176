<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">
        <!-- logo -->
        <b-link
          class="brand-logo"
          :href="platformUrl()"
        >
          <platform-logo />

          <h2 class="brand-text text-primary ml-1">
            {{ platformName() }}
          </h2>

          <small
            v-if="platformVersionName()"
            class="pl-50"
          >
            {{ platformVersionName() }}
          </small>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('auth.resetPassword.title') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('auth.resetPassword.email_hint') }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="resetPasswordForm">
          <b-form
            method="POST"
            class="auth-reset-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              :label="$t(`inputs.email`)"
              label-for="reset-password-email"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t(`inputs.email`)"
                rules="required|email"
              >
                <b-form-input
                  id="reset-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="reset-password-email"
                  placeholder="myemail@example.com"
                  :disabled="isLoading"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <hr>
            <b-card-text class="mt-2">
              {{ $t('auth.resetPassword.password_hint') }}
            </b-card-text>
            <!-- password -->
            <b-form-group
              :label="$t('inputs.new_password')"
              label-for="reset-password-new"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('inputs.new_password')"
                vid="password"
                rules="required|min:8"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="password"
                    placeholder="············"
                    :disabled="isLoading"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group
              label-for="password_confirmed"
              :label="$t('inputs.password_confirmation')"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('inputs.password_confirmation')"
                rules="required|confirmed:password"
                vid="password_confirmed"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password_confirmed"
                    v-model="cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="password_confirmed"
                    placeholder="············"
                    :disabled="isLoading"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="isLoading"
            >
              <template v-if="isLoading">
                <b-spinner small />
                <span class="sr-only">Loading...</span>
              </template>
              <span v-else>{{ $t('auth.resetPassword.btn_title') }}</span>
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link :to="{name:'auth-login-v1'}">
            <feather-icon icon="ChevronLeftIcon" />
            {{ $t('auth.resetPassword.back_to_login') }}
          </b-link>
        </p>
      </b-card>
    <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import PlatformLogo from '@core/layouts/components/Logo.vue'
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { confirmed, required } from '@validations'
import {
  catchException, platformName, platformUrl, platformVersionName, toast,
} from '@core/utils/utils'
import useSession from '@/auth/jwt/useJwt'

export default {
  components: {
    BSpinner,
    PlatformLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      isLoading: false,
      // validation
      required,
      // weak_password,
      confirmed,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    platformUrl,
    platformVersionName,
    platformName,
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.resetPasswordForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          useSession.csrf().then(() => {
            useSession.resetPassword({
              email: this.userEmail,
              password: this.password,
              password_confirmation: this.cPassword,
              token: this.$route.params.token,
            })
              .then(response => {
                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
                this.$router.replace({ name: 'auth-login' })
                  .then(() => {
                    toast(
                      'success',
                      response.data.message,
                    )
                  })
              })
              .catch(error => {
                if (error?.response?.status === 422) {
                  toast('warning', error.response.data.message, null, 'AlertTriangleIcon')
                  this.$refs.registerForm.setErrors(error.response.data.errors)
                } else {
                  catchException(error)
                  toast('danger', error?.response?.data?.message || error.message/* , errorText */)
                }
              })
              .finally(() => {
                this.isLoading = false
              })
          }).catch(() => {
            this.isLoading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
